import * as React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { linkResolver } from './src/_utils/linkResolver';

const PageTemplate = React.lazy(() => import('./src/templates'));

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
        linkResolver,
        componentResolver: { page: PageTemplate },
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
