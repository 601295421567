const fixSlashes = (str) => str.replace(new RegExp('/{2,}', 'g'), '/');
// fixSlashes removes double slashes, for example, "//login" becomes "/login"
// double slashes can occur when ${currentLang} is an empty string

exports.linkResolver = (doc) => {
  const { type, uid = '', lang, slug } = doc;
  // global meaning this is the generic non-language English page

  const currentLang = lang === 'en-nz' ? '' : lang;

  switch (type) {
    case 'lesmills_plus_landing_page':
    case 'les_mills_plus_tiering_landing_page':
    case 'les_mills_plus_common_landing_page':
    case 'promotion_landing_page': {
      const id = uid === 'index' ? '' : `${uid || slug}/`;
      return fixSlashes(`/${id}/${currentLang}`);
    }
    case 'affiliate_landing_page':
      return fixSlashes(`/offers/${currentLang}`);
    default:
      return '/';
  }
};
